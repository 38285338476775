<template>
	<Home />
</template>

<script>
import Home from '../components/Home';

export default {
  components: {
    Home,
  },
};
</script>
