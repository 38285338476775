<template>
    <v-app>
        <v-toolbar
            app
            color="primary"
            id="toolbar"
        >
            <img
                :src="require('./assets/img/logo.svg')"
                alt="SYS NFe"
                id="logo"
                height="100%"
                class="ml-5"
                @click="$vuetify.goTo('#home', scroll.options)"
            />

            <v-spacer></v-spacer>

            <v-toolbar-items class="hidden-sm-and-down">

                <v-btn
                    flat
                    @click="$vuetify.goTo('#quem-somos', scroll.options)"
                >
                    <span class="white--text font-weight-bold toobar-item">Quem somos</span>
                </v-btn>

                <v-btn
                    flat
                    @click="$vuetify.goTo('#trajetoria', scroll.options)"
                >
                    <span class="white--text font-weight-bold toobar-item">Trajetória</span>
                </v-btn>

                <v-btn
                    flat
                    @click="$vuetify.goTo('#plano', scroll.options)"
                >
                    <span class="white--text font-weight-bold toobar-item">Plano</span>
                </v-btn>

                <v-btn
                    flat
                    @click="$vuetify.goTo('#rodape', scroll.options)"
                >
                    <span class="
                    white--text
                    font-weight-bold
                    toobar-item">Contato</span>
                </v-btn>

            </v-toolbar-items>
            <v-toolbar-items>
                <!-- <v-btn
                    flat
                    href="#"
                >
                    <span class="teal--text text--lighten-3 font-weight-bold toobar-item">
                        Criar conta
                    </span>
                </v-btn>
                <v-icon>person_add</v-icon> -->

                <v-btn
                    flat
                    href="#"
                    @click="showLogar = !showLogar"
                >
                    <span class="green--text text--lighten-3 font-weight-bold toobar-item">
                        Logar
                    </span>
                </v-btn>
                <v-icon class="ml-2">vpn_key</v-icon>
            </v-toolbar-items>
        </v-toolbar>

        <v-content>

            <v-dialog
                v-model="showLogar"
                max-width="400px"
            >
                <Logar></Logar>
            </v-dialog>

            <main>
                <router-view />
            </main>
        </v-content>
    </v-app>
</template>

<script>

import Logar from './components/Logar.vue'

export default {
  name: 'App',
  components: {
    Logar,
  },
  data() {
    return {
      isMobile: false,
      showLogar: false,
      scroll: {
        options() {
          return {
            duration: 5000,
            offset: -1000,
            easing: 'easeInCubic',
          }
        },
      },
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },

  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
  },
};
</script>

<style scoped>
</style>
