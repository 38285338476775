import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=c10089d0&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&id=c10089d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c10089d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/flavyo/Desenvolvimento/JS/site-sysnfe/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VFooter } from 'vuetify/lib'
import { VForm } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSnackbar } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VContainer,VFlex,VFooter,VForm,VIcon,VImg,VLayout,VSnackbar,VSpacer,VTextField,VTextarea})
