import { render, staticRenderFns } from "./App.vue?vue&type=template&id=3df8ec24&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df8ec24",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/flavyo/Desenvolvimento/JS/site-sysnfe/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarItems } from 'vuetify/lib'
installComponents(component, {VApp,VBtn,VContent,VDialog,VIcon,VSpacer,VToolbar,VToolbarItems})
