<template>
    <v-card>
        <v-snackbar
            v-model="snackbar.show"
            :multi-line="true"
            :timeout="6000"
            :color="snackbar.color"
            top
            right
        >
            {{ snackbar.text }}
        </v-snackbar>

        <v-card-title>
            <span class="headline text-md-center">Informe o CNPJ da empresa</span>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12>

                        <v-text-field
                            v-model="cnpj"
                            clearable
                            label="CNPJ"
                            mask="##.###.###/####-##"
                            single-line
                            box
                            counter="14"
                        />
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                flat
                @click="logar"
            >Acessar</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
  data: () => ({
    cnpj: '',
    snackbar: { show: false, text: '', color: 'error' }, // Controla as mensagens de aviso para o usuário
  }),
  methods: {
    logar() {
      if (this.cnpj) {
        const url = `https://sysnfe.app/logar-cnpj?cnpj=${this.cnpj}`

		fetch(url).then((res) => {
            if (res.status === 200) {
              res.json()
                .then((data) => {
                  window.location = `https://${data.url}`
                })
            } else if (res.status === 404) {
              this.snackbar.color = 'error'
              this.snackbar.show = true
              this.snackbar.text = 'CNPJ não encontrado!'
            }
          }).catch((res) => {
            this.snackbar.color = 'error'
            this.snackbar.show = true
            this.snackbar.text = 'Ouve um erro ao buscar os dados do CNPJ, por favor, tente novamente!'
          })
      }
    },
  },
}
</script>
