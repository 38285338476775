<template>
    <v-layout column>

        <v-snackbar
            v-model="snackbar.show"
            :multi-line="true"
            :timeout="6000"
            :color="snackbar.color"
            top
            right
        >
            {{ snackbar.text }}
        </v-snackbar>

        <v-layout
            text-xs-center
            wrap
            id="home"
        >
            <v-flex xs12>
                <v-img
                    :src="require('../assets/img/logo_azul.svg')"
                    class="my-3"
                    contain
                    :height="logoHeigth"
                />
            </v-flex>

            <v-flex
                xs12
                mb-5
            >
                <h1 class="display-2 font-weight-bold mb-3">Seja bem vindo ao SYS NF-e!</h1>
                <p class="headline font-weight-regular">
                    Queremos te oferecer uma experiência incrível na hora de faturar
                </p>
            </v-flex>
        </v-layout>

        <v-layout
            text-xs-center
            wrap
            mt-5
            id="quem-somos"
            class="light-green"
        >
            <v-container class="mb-5 mt-5">
                <v-flex
                    mb-5
                    xs12
                >
                    <h2 class="headline font-weight-bold mb-3 title">Quem somos?</h2>

                    <v-layout
                        justify-center
                        row
                        wrap
                    >
                        <v-card
                            class="ml-2 elevation-4 card-valor"
                            color="text--lighten-1 teal--text"
                            max-width="400"
                            xs12
                            md4
                            v-for="(content, i) in quemSomos"
                            :key="i"
                        >
                            <v-card-title>
                                <v-icon left>done</v-icon>
                                <span class="title font-weight-medium">{{ content.title }}</span>
                            </v-card-title>

                            <v-card-text class="font-weight-ligth text-xs-center">
                                {{ content.text }}
                            </v-card-text>
                        </v-card>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-layout>

        <!-- Trajetoria -->
        <v-layout
            text-xs-center
            wrap
            class="light-green lighten-4"
            id="trajetoria"
        >
            <v-flex
                xs12
                mb-5
                mt-5
            >
                <v-layout justify-center>
                    <v-container class>
                        <h2 class="title text-md-center font-weight-bold mt-5">Trajetória</h2>
                        <p class="blockquote">
                            O caminho é um pouco longo, mais vamos tentar resumir:
                            temos uma vasta experiência trabalhando em Softwares
                            Houses e começamos a observar que uma certa fatia de
                            empresas do mercado tem as suas demandas negligenciadas,
                            o empreśario desprezado, com sistema muito complexo e
                            burocrático, o que o torna de difícil utilização.
                        </p>

                        <p class="blockquote font-weight-bold">Estamos falando de
                            empresas que estão no regime do Simples Nacional e do
                            Micro Empreendedor (MEI).</p>

                        <p class="blockquote">
                            Com o advindo de obrigações de emissão fiscal em ECF e
                            NFC-e, muitos amigos e parceiros começaram a nos procurar
                            pedindo indicação de sistemas que atendessem as suas necessidades,
                            outros, nos pediam ajuda por não conseguirem utilizar o sistema,
                            muitas opções de difícil compreensão, sistemas com funções
                            complexas e de nenhuma utilização, funções que nem contadores
                            sabem para que serve.
                        </p>
                        <p class="blockquote">
                            Identificamos então uma grande oportunidade e decidimos
                            juntar alguns parceiros com experiência na área para
                            desenvolver uma solução simples, eficiente, robusta
                            e de baixo custo, que atenda empresas que estejam
                            enquadradas como MEI e uma fatia de empresas do
                            Simples Nacional, com um produto que os atenda
                            com excelência.
                        </p>
                        <p class="blockquote">
                            Para atender a necessidade dessas empresas, criamos
                            o SYS NFe, um emissor fiscal de fácil utilização, preço acessível e
                            atraente, além de moderno e resiliente.
                        </p>
                        <p class="blockquote">
                            Construimos um Sistema que roda em Cloud, em um ambiente
                            tolerante a falhas e com backups automáticos.
                            Esqueça a história de ficar fazendo backups dos seus XMLs,
                            nós guardamos todos os XMLs em nosso ambiente
                            pelos 5 anos exigidos pela legislação.
                        </p>
                    </v-container>
                </v-layout>
            </v-flex>
        </v-layout>

        <!-- Valor -->
        <v-layout
            text-xs-center
            wrap
            class="light-green"
            id="plano"
        >
            <v-container>
                <v-flex mb-5>
                    <h2 class="headline font-weight-bold mb-3 title">Por apenas R$ 49,99 tenha</h2>

                    <v-layout
                        justify-center
                        row
                        wrap
                    >
                        <v-flex
                            v-for="(content, i) in recursos"
                            :key="i"
                            md4
                            xs12
                        >
                            <v-card
                                class="elevation-4 card-valor"
                                color="text--lighten-1 teal--text"
                                xs12
                            >
                                <v-card-title>
                                    <v-icon left>done</v-icon>
                                    <span class="title font-weight-medium">
                                        {{ content.title }}
                                    </span>
                                </v-card-title>

                                <v-card-text class="font-weight-ligth">
                                    {{ content.text }}
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-layout>

        <!-- Contato -->
        <v-footer
            class="light-green light-green lighten-4 column flex"
            height="auto"
            id="rodape"
            width="auto"
        >
            <v-layout wrap>
                <v-flex
                    xs12
                    md6
                >
                    <v-layout justify-center>
                        <v-container class="border-rigth my-3 mx-4">
                            <h3 class="headline font-weight-bold mb-3 title text-xs-center">
                                Contato
                            </h3>

                            <v-form
                                lazy-validation
                                ref="formContato"
                            >
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex
                                            xs12
                                            md6
                                        >
                                            <v-text-field
                                                v-model="contato.nome"
                                                clearable
                                                label="Nome"
                                                required
                                                single-line
                                                box
                                                background-color="light-green lighten-3"
                                                counter="60"
                                                :rules="[rules.required]"
                                            />
                                        </v-flex>

                                        <v-flex
                                            xs12
                                            md6
                                        >
                                            <v-text-field
                                                v-model="contato.email"
                                                clearable
                                                label="E-mail"
                                                required
                                                single-line
                                                box
                                                background-color="light-green lighten-3"
                                                type="email"
                                                counter="50"
                                                :rules="[rules.required, rules.email]"
                                            />
                                        </v-flex>

                                        <v-flex
                                            xs12
                                            md6
                                        >
                                            <v-text-field
                                                v-model="contato.telefone"
                                                clearable
                                                label="Telefone"
                                                required
                                                single-line
                                                box
                                                background-color="light-green lighten-3"
                                                type="tel"
                                                counter="11"
                                                mask="(##) # ####-####"
                                                :rules="[rules.required]"
                                            />
                                        </v-flex>

                                        <v-flex
                                            xs12
                                            md6
                                        >
                                            <v-text-field
                                                v-model="contato.cnpj"
                                                clearable
                                                label="CNPJ"
                                                mask="##.###.###/####-##"
                                                single-line
                                                box
                                                background-color="light-green lighten-3"
                                                counter="14"
                                            />
                                        </v-flex>
                                    </v-layout>

                                    <v-layout>
                                        <v-flex
                                            xs12
                                            md12
                                        >
                                            <v-textarea
                                                v-model="contato.mensagem"
                                                clearable
                                                label="Mensagem"
                                                required
                                                outline
                                                single-line
                                                :counter="255"
                                                background-color="light-green lighten-1"
                                                :rules="[rules.required]"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-btn
                                    @click="submit"
                                    id="btn-enviar"
                                >Enviar</v-btn>
                            </v-form>
                        </v-container>

                        <v-spacer>
                        </v-spacer>
                    </v-layout>
                </v-flex>

                <v-flex
                    xs12
                    md6
                >

                    <v-layout
                        row
                        justify-center
                        text-xs-center
                    >
                        <v-container class="my-3 mx-4">
                            <v-flex class="flex-endereco">
                                <h3 class="headline font-weight-bold mb-5 title text-xs-center">
                                    Endereço
                                </h3>
                                <address>
                                    <p>Rua 504, 214 - Setor Centro Oeste, Goiânia/GO, 74550-160</p>
                                </address>

                                <p class="mt-4">
                                    <a href="tel:+6241417053">
                                        <v-icon>phone</v-icon> (62) 4141-7053
                                    </a>
                                </p>
                                <div
                                    class="mt-4"
                                    id="redes-sociais"
                                >
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.facebook.com/FN-Sistemas-437700343751467/"
                                    >
                                        <img
                                            :src="require('../assets/img/facebook.svg')"
                                            alt="facebook"
                                            height="24"
                                        >
                                        <span class="hidden-xs-only">FN-Sistemas</span>
                                    </a>

                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.instagram.com/fnsistemas"
                                    >
                                        <img
                                            :src="require('../assets/img/instagram.svg')"
                                            alt="instagram"
                                            height="24"
                                        >
                                        <span class="hidden-xs-only">@fnsistemas</span>
                                    </a>

                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://api.whatsapp.com/send?phone=556241417053&text=Olá! Quero mais informações sobre o sistema de emissão de notas SYS NFe"
                                    >
                                        <img
                                            :src="require('../assets/img/whatsapp.svg')"
                                            alt="WhatsApp"
                                            height="24"
                                        >
                                        <span class="hidden-xs-only">(62) 4141-7053</span>
                                    </a>
                                </div>
                                <p class="mt-4 ml-5">
                                    <a href="mailto:contato@fnsistemas.com.br">
                                        <v-icon>mail</v-icon>
                                        contato@fnsistemas.com.br
                                    </a>
                                </p>

                                <v-layout
                                    id="logo-fn"
                                    xs12
                                    justify-start
                                >
                                    <v-spacer></v-spacer>
                                    <v-flex
                                        xs12
                                        md2
                                    >
                                        <img
                                            :src="require('../assets/img/logo_fn.svg')"
                                            height="70"
                                        >
                                    </v-flex>
                                    <v-flex
                                        xs12
                                        md6
                                        text-md-left
                                    >
                                        <p>&copy; Um produto FN Sistemas</p>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-container>
                    </v-layout>
                </v-flex>
            </v-layout>

        </v-footer>
    </v-layout>
</template>

<script>
export default {
  data: () => ({
    snackbar: { show: false, text: '', color: 'error' }, // Controla as mensagens de aviso para o usuário
    quemSomos: [
      {
        title: 'Inconformáveis',
        text:
                    'Somos inconformáveis com as complexidades impostas na hora de faturar',
      },
      {
        title: 'Diferentes',
        text:
                    'Acreditamos que a hora de realizar uma venda é um momento de alegria e não de dor',
      },
      {
        title: 'Queremos mudança',
        text:
                    'Desenvolvemos uma ferramenta pensando em facilitar a vida do empresário, trazendo facilidade e simplicidade para a rotina de faturamento',
      },
      {
        title: 'Melhores',
        text:
                    'A nossa ferramenta foi desenvolvida para rodar em Cloud, você consegue acessar de qualquer lugar precisando apenas de um computador com acesso a internet',
      },
    ],
    recursos: [
      {
        title: 'Backup',
        text:
                    'Seus dados sempre protegidos! Backups realizados periodicamente e os XMLs armazenados por 5 anos',
      },
      {
        title: 'Segurança',
        text:
                    'Acesso ao site criptografado por SSL em um ambiente Cloud de alta disponibilidade',
      },
      {
        title: 'NF-e',
        text: 'Faça a emissão e cancelamento de NFe sem limite',
      },

      {
        title: 'NFC-e',
        text: 'Faça a emissão e cancelamento de NFCe sem limite',
      },
      {
        title: 'Cadastro de cliente',
        text: 'Cadastro de cliente sem limite de cadastro',
      },
      {
        title: 'Produto',
        text: 'Cadastro de produto sem limite de registro',
      },
      {
        title: 'Imposto',
        text:
                    'Tela simplificada do cadastro de imposto, na qual você cadastra '
                    + 'e reaproveita o seu cadastro para vários produtos',
      },
      {
        title: 'XML',
        text:
                    'Precisa do XML? Sem problemas, você pode baixar sempre que precisar',
      },
      {
        title: 'Envio de e-mail',
        text:
                    'Ao realizar uma nova venda e identificar o cliente, nós enviamos '
                    + 'automaticamente o DANFE e o XML para o seu cliente caso tenha sido '
                    + 'informado o e-mail',
      },
      {
        title: 'Suporte',
        text:
                    'Suporte gratuito, sempre disponível via telefone e e-mail',
      },
    ],
    logoHeigth: 700,
    contato: {
      nome: '',
      email: '',
      telefone: '',
      cnpj: '',
      mensagem: '',
    },
    rules: {
      required: value => !!value || 'Esse campo é obrigatório',
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail inválido'
      },
    },
  }),
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    onResize() {
      // const tamanhoTela = window.innerHeight
      const tamanhoTela = window.innerWidth
      const isPortrait = window.matchMedia('(orientation: portrait)').matches
      const isMobile = tamanhoTela < 968

      this.logoHeigth = 700

      if (isMobile) {
        if (tamanhoTela < 350) {
          this.logoHeigth = 300
        } else if (tamanhoTela < 450) {
          this.logoHeigth = 400
        } else if (tamanhoTela < 640) {
          this.logoHeigth = 600
        } else if (tamanhoTela < 740) {
          this.logoHeigth = 650
        } else if (isPortrait) {
          this.logoHeigth = 700
        } else {
          this.logoHeigth = tamanhoTela > 1400 ? 700 : 400
        }
      } else {
        this.logoHeigth = tamanhoTela > 1400 ? 700 : 400
      }
    },
    submit() {
      if (this.$refs.formContato.validate()) {
        let url = 'https://sysnfe.app/contato?'
        url += `nome=${this.contato.nome}&email=${this.contato.email}&`
        url += `telefone=${this.contato.telefone}&mensagem=${this.contato.mensagem}`
        url += `&cnpj=${this.contato.cnpj}`

        fetch(url).then((res) => {
          if (res.status === 200) {
            this.snackbar.color = 'success'
            this.snackbar.text = 'Dados de contato enviado! Em breve entraremos em contato'
            this.snackbar.show = true
          } else {
            this.snackbar.color = 'error'
            this.snackbar.show = true
            this.snackbar.text = 'Ocorreu um erro ao enviar os dados de contato!'
          }
        }).catch((error) => {
          this.snackbar.color = 'error'
          this.snackbar.show = true
          this.snackbar.text = 'Ocorreu um erro ao enviar os dados de contato!'
        })
      } else {
        this.snackbar.color = 'error'
        this.snackbar.show = true
        this.snackbar.text = 'É necessário preencher corretamente os campos!'
      }
    },
  },
};
</script>
<style scoped>
* {
    font-size: 1.017em;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.6);
}

.title {
    color: #004d40ef;
    font-size: 1.3em !important;
}

div.card-valor {
    height: 220px;
    margin-top: 20px;
    margin-left: 1em;
}

div.card-valor:hover {
    /* background-color: rgba(134, 233, 134, 0.377) !important; */
    color: rgba(255, 255, 255, 0.7) !important;

    font-weight: 900;
}

#btn-enviar {
    margin-left: 80px;
}

a {
    text-decoration: none;
}

address {
    text-decoration: none;
    font-style: initial;
}

@media only screen and (min-width: 930px) {
    .border-rigth {
        border-right: 1px solid;
        border-color: #ccc;
    }

    #btn-enviar {
        margin-left: 250px;
    }

    #logo-fn {
        margin: 2.2em auto 0 auto;
    }

    #logo-fn:last-child p {
        margin-top: 2em;
        margin-left: -0.8em;
    }
}

@media only screen and (min-width: 1400px) {
    #btn-enviar {
        margin-left: 380px;
    }
}

@media only screen and (max-width: 1000px) {
    #redes-sociais a {
        margin-left: 2em;
    }
}

@media only screen and (min-width: 1000px) {
    #btn-enviar {
        margin-left: 380px;
    }

    #redes-sociais {
        display: flex;
        align-items: center;
        position: relative;
        left: 20%;
    }

    #redes-sociais a:nth-child(2) {
        margin: 0 15px;
    }

    #redes-sociais a span {
        margin-left: 10px;
        margin-bottom: 5px;
        position: relative;
        bottom: 5px;
    }
}
</style>
